import {createContext, useReducer} from 'react';
import LoginScreen from "./components/LoginScreen/LoginScreen";
import GameScreen from "./components/GameScreen/GameScreen";

const initialState = {
    screen: <GameScreen />,
    username: '',
};

const Context = createContext(initialState);

const Store = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    );
};

const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_SCREEN':
            return {
                ...state,
                screen: action.payload
            };
        case 'SET_USERNAME':
            return {
                ...state,
                username: action.payload
            };
        default:
            return {
                ...state
            };
    }
};

export {Context, Store};