import {
    GiClubs,
    GiHearts,
    GiSpades,
    GiDiamonds,
    GiOldKing,
    GiQueenCrown,
    GiCardJoker,
    GiAlliedStar, GiAbstract049, GiAbstract053, GiAbstract066, GiAbstract038
} from 'react-icons/gi';
import {useEffect, useState} from "react";
import './Card.scss';

export default function Card( {suit, value} ) {
    const [ color, setColor ] = useState( 'black' );
    const [ rotated, setRotated ] = useState( false );

    useEffect(() => {
        if (suit === 'diamonds' || suit === 'hearts') {
            setColor('red')
        } else {
            setColor('black');
        }
    }, [suit]);

    const getSuitSvg = (i = undefined) => {
        switch (suit) {
            case 'clubs':
                return <GiClubs key={i} />;
            case 'diamonds':
                return <GiDiamonds key={i} />;
            case 'hearts':
                return <GiHearts key={i} />;
            case 'spades':
                return <GiSpades key={i} />;
            case 'joker':
                return <GiAlliedStar key={i} />;
            default:
                return '';
        }
    };

    const getSuitSvgs = () => {
        if (isNaN(value)) {
            if (value === 'A') return <div className={'card_values card_values-center ace'}>{getSuitSvg()}</div>;
            return <SpecialValue svg={getSuitSvg()} value={value} />;
        }
        let halfSvgs = [];

        let isEven = value % 2 === 0;
        let evenValue = isEven ? value : value - 1;

        for (let i = 0; i < evenValue * .5; i++) {
            halfSvgs.push(getSuitSvg(i));
        }

        let centerSvg = isEven ? '' : getSuitSvg();

        let leftSvgs = <div className={'card_values card_values-left'}>{halfSvgs}</div>;
        let rightSvgs = <div className={'card_values card_values-left'}>{halfSvgs}</div>;
        let centerSvgs = <div className={'card_values card_values-center'}>{centerSvg}</div>;

        return [leftSvgs, centerSvgs, rightSvgs];
    };

    const getPattern = () => {
        let pattern = [];

        for (let i = 0; i < 77; i++) {
            pattern.push(<GiAbstract038 />);
        }

        return pattern;
    };

    return (
        <div onClick={() => setRotated(!rotated)} className={'card nohover ' + color + ' ' + (rotated ? 'rotated' : '')}>
            <span className={'card_value card_value-topLeft'}>{value}</span>
            <span className={'card_suit card_suit-topLeft'}>{getSuitSvg()}</span>
            <span className={'card_valuesWrapper'}>
                {getSuitSvgs()}
            </span>
            <span className={'card_suit card_suit-bottomRight'}>{getSuitSvg()}</span>
            <span className={'card_value card_value-bottomRight'}>{value}</span>
            <div className={'card_pattern'}><div>{getPattern()}</div></div>
        </div>
    );
}

function SpecialValue({ svg, value }) {

    const getValueSvg = () => {
        switch (value) {
            case 'K':
                return <GiOldKing />;
            case 'Q':
                return <GiQueenCrown />;
            case 'J':
                return 'J';
            case 'JOKER':
                return <GiAlliedStar />;
        }
    };

    return (
        <div className={'card_values card_values-special'}>
            <div className={'card_values-special_suit card_values-special_suit-topRight'}>{svg}</div>
            <div className={'card_values-special_value card_values-special_value-topLeft'}>{getValueSvg()}</div>
            <div className={'card_values-special_value card_values-special_value-bottomRight'}>{getValueSvg()}</div>
            <div className={'card_values-special_suit card_values-special_suit-bottomLeft'}>{svg}</div>
        </div>
    );
}