import './GameScreen.scss';
import Card from "../Card/Card";
import {useState} from "react";

export default function GameScreen() {

    const [suit, setSuit] = useState('hearts');

    return (
        <>
                <div className={'game-screen_selector'}>
                        <span onClick={() => setSuit('diamonds')}>Diamonds</span>
                        <span onClick={() => setSuit('hearts')}>Hearts</span>
                        <span onClick={() => setSuit('spades')}>Spades</span>
                        <span onClick={() => setSuit('clubs')}>Clubs</span>
                        <>Click The Cards To Rotate Them</>
                </div>
                <section className={'screen game-screen'}>
                        <Card suit={suit} value={'A'}/>
                        <Card suit={suit} value={'2'}/>
                        <Card suit={suit} value={'3'}/>
                        <Card suit={suit} value={'4'}/>
                        <Card suit={suit} value={'5'}/>
                        <Card suit={suit} value={'6'}/>
                        <Card suit={suit} value={'7'}/>
                        <Card suit={suit} value={'8'}/>
                        <Card suit={suit} value={'9'}/>
                        <Card suit={suit} value={'10'}/>
                        <Card suit={suit} value={'J'}/>
                        <Card suit={suit} value={'Q'}/>
                        <Card suit={suit} value={'K'}/>
                        <Card suit={'joker'} value={'JOKER'}/>
                </section>
        </>
    );
}

