import './Footer.scss';

export default function Footer() {
    return (
        <footer className={'footer'}>
            <span className={'footer_logo'}>bullshit v0.0.1</span>
            <div className={'footer_attributions'}>

            </div>
        </footer>
    );
}