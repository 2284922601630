// noinspection JSCheckFunctionSignatures

import './LoginScreen.scss';
import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {Context} from "../../Store";
import LobbyScreen from "../LobbyScreen/LobbyScreen";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

export default function LoginScreen() {
    const [ username, setUsername ] = useState('');

    const [state, dispatch] = useContext(Context);

    const input = useRef(document.createElement('input'));

    useEffect(() => {
        if (input) input.current.focus();
    }, []);

    const login = async (e) => {
        e.preventDefault();

        if (/^(?=[a-zA-Z0-9._]{1,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/g.test(username)) {
            // Send request to server
            try {
                const res = (await axios.post('/register', { username })).data;
                if (res.jwt) {
                    localStorage.setItem('token', res.jwt);
                    console.log('Sheesh');
                    dispatch({type: 'SET_USERNAME', payload: username});
                    dispatch({type: 'SET_SCREEN', payload: <LobbyScreen />});
                }
            } catch (e) {
                console.dir(e);
            }
        }
    };

    return (
        <div className={'login-screen'}>
            <form>
                <h1>Enter A Username:</h1>
                <input ref={input} onChange={e => setUsername(e.currentTarget.value)} className={'login-screen_input'} type={'text'} value={username} />
                <button onClick={e => login(e)}>Enter</button>
            </form>
        </div>
    );
}