// noinspection JSCheckFunctionSignatures

import React, {useContext} from 'react';
import './App.scss';
import Footer from "./components/Footer/Footer";
import {Context} from "./Store";

function App() {
    const [state, dispatch] = useContext(Context);

    return (
        <div className="App">
            {state.screen}
            <Footer/>
        </div>
    );
}

export default App;
